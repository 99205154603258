<template>
  <div>
    <div v-if="error" class="h-screen flex items-center justify-center flex-col">
      <img
        :src="system.logo_light_url"
        :alt="system.name"
        class="max-w-sm max-h-24 inline-block"
      />
      <h1 class="font-bold mb-1 text-lg mt-12">Error</h1>
      <p>{{ error }}</p>
    </div>

    <div v-else-if="order === null" class="h-screen flex items-center justify-center flex-col">
      <img
        :src="system.logo_light_url"
        :alt="system.name"
        class="max-w-sm max-h-24 inline-block"
      />

      <loader class="mt-12" />
    </div>

    <div v-else>
      <div class="container my-6 px-6">
        <div class="flex justify-between mb-6">
          <img
            v-if="system.use_agent_logo"
            :src="order.office.logo"
            :alt="order.office.name"
            class="max-w-sm max-h-24 inline-block"
          />

          <img
            :src="system.logo_light_url"
            :alt="system.name"
            class="max-w-sm max-h-24 inline-block"
          />
        </div>

        <h1 class="mb-3 text-4xl font-bold">Your Marketing Pack - Source Material Review</h1>
        <p>Order Number: {{ order.id }}.</p>
        <p>Property: {{ order.property }}</p>

        <div class="px-6 py-3 bg-base text-white flex my-6">
          <i class="fas fa-info-circle"></i>
          <div class="pl-6 flex-grow">
            Please review the source material below. We will use these assets in our marketing materials for your property. If you wish to, you can select up to 35 of your preferred photos, we will prioritise the use of these where appropriate in the marketing materials. Select these by clicking the star in the top right corner of your preferred photos. If you are happy with the content, please click the approve button at the bottom of the page. To request amends, please contact us on {{ order.office.phone }} or email us at <a :href="`mailto:${order.office.email}`" class="hover:underline">{{ order.office.email }}</a>.
          </div>
        </div>

        <div v-for="(item, i) in visibleItems" :key="i" class="mb-12">
          <div class="flex">
            <h2 class="mb-3 text-2xl font-bold w-1/2">{{ item.name }}</h2>
            <div v-if="[2, 3].includes(item.item_id)" class="w-1/2">
              <h4 class="mb-6 mt-2 text-right" :class="{ 'text-red-600': preferredPhotos.length >= 35 }">Favourites selected ({{ preferredPhotos.length }}/35)</h4>
            </div>
          </div>

          <div class="flex flex-wrap -mx-2">
            <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 mb-4" v-for="(attachment, j) in item.attachments" :key="j">
              <div v-if="attachment.thumb" class="relative">
                <img  :src="attachment.thumb" :class="[2,3].includes(item.item_id) ? 'mb-4' : ''" class="w-full cursor-pointer" @click="openGallery(item.attachments, j)" />
                <div v-if="[2,3].includes(item.item_id)">
                  <button v-if="attachment.is_preferred_by_vendor" class="absolute top-0 right-0 bg-gray-100 p-2" @click="setPreferred(attachment, false)">
                    <i class="fas fa-star fa-lg text-yellow-500"></i>
                  </button>
                  <modal  v-else-if="preferredPhotos.length === 35" :component="'div'" heading="Warning">
                    <template v-slot:trigger>
                      <button >
                        <i class="far fa-star fa-lg text-white"></i>
                      </button>
                    </template>
                    <p class="mb-3">
                      A maximum of 35 preferred photos can be selected. In order to mark this as a preferred photo, please deselect one of the others first.
                    </p>
                  </modal>
                  <button class="absolute top-0 right-0 bg-gray-100 p-2" v-else @click="setPreferred(attachment, true)">
                    <i class="far fa-star fa-lg text-gray-400"></i>
                  </button>
                </div>
                <p v-if="[2,3].includes(item.item_id)" class="text-center truncate">{{ attachment.filename }}</p>
              </div>

              <secondary-link v-else class="w-full" :href="attachment.url">
                <i class="fas fa-download"></i> {{ attachment.filename }}
              </secondary-link>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white py-3 sticky bottom-0 shadow">
        <div class="container px-6">
          <div class="flex justify-end">
            <span v-if="approved"><i class="fas fa-check-circle"></i> Thank you. Source material approved. You may now close this window.</span>
            <loader v-else-if="approving" />
            <primary-btn v-else @click="approve">
              <i class="fas fa-check-circle"></i> Approve Source Material
            </primary-btn>
          </div>
        </div>
      </div>

      <gallery v-if="galleryImages.length" :images="galleryImages" :index="galleryIndex" @close="galleryImages = []" />
    </div>
  </div>
</template>

<script>
import api from "@/http";
import Loader from "../../components/Loader";
import SecondaryLink from "../../components/SecondaryLink";
import Modal from "@/components/ModalComponent";
import PrimaryBtn from "../../components/PrimaryBtn";
import Gallery from "../../components/Gallery";
import { filter } from 'lodash'
import { mapState } from "vuex/dist/vuex.common.js";

export default {
  components: {
    Loader,
    SecondaryLink,
    PrimaryBtn,
    Gallery,
    Modal
  },
  data() {
    return {
      order: null,
      error: null,
      galleryImages: [],
      galleryIndex: 0,
      approving: false,
      approved: false,
      maxImageMessage: false
    };
  },
  computed: {
    ...mapState(['system']),
    visibleItems() {
      if(!this.order) {
        return []
      }

      if(!this.$route.query.type) {
        return this.order.items
      }

      const ids = this.$route.query.type.split(',')
      if(ids.length === 0) {
        return this.order.items
      }

      return filter(this.order.items, (item) => ids.includes(item.item_id.toString()) && item.attachments.length > 0)
    },
    photoAttachments() {
      if (!this.order) {
        return []
      }

      let output = []
      const items = filter(this.order.items, (item) => [11, 15, 16].includes(item.internal_type_id))
      for (let i = 0; i < items.length; i++) {
        for (let a = 0; a < items[i].attachments.length; a++) {
          output.push(items[i].attachments[a])
        }
      }

      return output
    },
    preferredPhotos() {
      return filter(this.photoAttachments, (attachment) => attachment.is_preferred_by_vendor)
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      api
        .get(`vendor/orders/${this.$route.params.orderId}`, {
          params: {
            signature: this.$route.query.signature
          },
        })
        .then((response) => {
          this.order = response.data
        }, (error) => {
          if(error.response.status === 404) {
            this.error = 'Order not found.'
            return
          }
          this.error = error.response.data.message
        })
    },
    setPreferred(attachment, setValue) {
      if (this.preferredPhotos.length === 35 && setValue === true) {
        this.maxImageMessage = true;
        return;
      }
      this.maxImageMessage = false;
      api.post(`vendor/orders/${this.$route.params.orderId}/attachment/${attachment.id}/setPreferred`, {
        is_preferred_by_vendor: setValue
      }, 
      {
        params: {
          signature: attachment.set_preferred_signature.signature,
          
        }
      }).then((response) => {
        this.order = response.data
      }, (error) => {
        this.error = error.response.data.message
      })
    },
    openGallery(images, i) {
      this.galleryImages = images
      this.galleryIndex = i
    },
    approve() {
      this.approving = true
      api.post(`vendor/orders/${this.$route.params.orderId}/approve`, null, {
        params: this.order.approval
      }).then(() => {
        this.approved = true
      }, (error) => {
        this.error = error.response.data.message
      })
    }
  }
};
</script>